<template>
  <div class="coverband">
    <VideoBanner :v-if='$route.name === "Coverband"' posterSrc='band.jpg' mp4Src='band.mp4' webmSrc='band.webm' :overlayTitle='$COMPANY.NAME' :overlayDescription='$COMPANY.DESCRIPTION' />
    <div class="main-content small-content">
      <b-container>
        <h2>Cover/Tribute &amp; Partyband</h2>
        <div class="coverband-content page-content">
          <b-row>
            <b-col>
              <h3>Band för alla tillfällen</h3>
              <p>Band för alla olika tillfällen! Partyband för att dra igång riktig partydans under kvällen Coverband &amp; afterskiband för fullt ös!</p>
              <p>Coverband med breda repertoarer.</p>
              <p>Tributeband som gör allt för att efterlikna och hylla sina idoler. Ofta överträffar dom originalet.</p>
              <p>Band som kompar kända gästartister &amp; shower samt gör mingel &amp; middagsunderhållning.</p>
              <p>Temaband som tex soul, rock, 60-tal, 70-tal och Disco m.m.</p>
              <p class="last-pop">Hör av dig till oss så tar vi tillsammans fram rätt temaband till erat event.</p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import VideoBanner from './VideoBanner'

export default {
  components: {
    VideoBanner
  }
}
</script>

<style>

</style>
