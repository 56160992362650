<template>
  <div class="troubadour">
    <VideoBanner :v-if='$route.name === "Troubadour"'  posterSrc='trubadur.jpg' mp4Src='trubadur.mp4' webmSrc='trubadur.webm' :overlayTitle='$COMPANY.NAME' :overlayDescription='$COMPANY.DESCRIPTION' />
    <div class="main-content small-content">
      <b-container>
        <h2>Trubadur</h2>
        <div class="troubadur-content page-content">
          <b-row>
            <b-col>
              <h3>Rutinerade trubadurer för alla tillfällen</h3>
              <p>Mingel underhållning till drinken, teman, allsång &amp; snapsvisor till maten,  pubunderhållning eller partytrubadur med full fart.</p>
              <p>Kanske ren bakgrundsunderhållning/cocktailmusik eller en kul musikfrågesport eller annan tävling m.m.</p>
              <p>Vistrubadur, underhållning, dans, party, afterski m.m.</p>
              <p class="last-pop">Vi hittar det som passar er tillställning</p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import VideoBanner from './VideoBanner'

export default {
  components: {
    VideoBanner
  }
}
</script>

<style>

</style>
