<template>
   <div class="splash" v-if="nextEvent !== null && nextEvent !== undefined">
     <div class="splash-content">
      <p>Nästa event:</p>
      <h2>{{nextEvent.description}}</h2>
      <h3>{{nextEvent.location}}</h3>
      <p>{{trimDate(nextEvent.date)}} - {{nextEvent.city}}</p>
      <div class="event-social">
        <span class="event-map event-item">
          <a v-if="nextEvent.map !== '' && nextEvent.map !== undefined && nextEvent.map !== null" :href="nextEvent.map" target="_blank"><i class="fa-solid fa-map-location-dot"></i></a>
        </span>
        <span class="event-fb event-item">
          <a v-if="nextEvent.fb !== '' && nextEvent.fb !== undefined && nextEvent.fb !== null" :href="nextEvent.fb" target="_blank"><i class="fa-brands fa-facebook-square"></i></a>
        </span>
        <span class="event-ig event-item">
          <a v-if="nextEvent.ig !== '' && nextEvent.ig !== undefined && nextEvent.ig !== null" :href="nextEvent.ig" target="_blank"><i class="fa-brands fa-instagram"></i></a>
        </span>
      </div>
      <p><a v-if="nextEvent.booking !== '' && nextEvent.booking !== undefined && nextEvent.booking !== null" :href="nextEvent.booking" target="_blank">Boka >></a></p>
      <p><router-link to="/kommande-event">Se fler events >></router-link></p>
     </div>
    </div>
</template>

<script>
import EventsService from '../sevices/EventsService'

const eventsService = new EventsService()

export default {
  components: {
  },
  methods: {
    trimDate (date) {
      return eventsService.trimDate(date)
    }
  },
  data () {
    return {
      nextEvent: eventsService.getNextEvent() || null
    }
  }
}
</script>

<style>
.splash {
  background: var(--color-background-2-hover);
  border-radius: 1rem;
  padding: 1rem 2rem;
  margin: 0 auto;
  animation: animateHeart 2s infinite;
}

.splash h2,
.splash h3,
.splash p {
  padding: 0;
  margin: 0 !important;
}

.splash-content {
  margin: auto;
  text-align: center;
}

.splash .event-item {
  font-size: 2rem;
  padding: 0 0.5rem;
  width: fit-content;
}

.event-social a:link,
.event-social a:visited,
.event-social a:active {
  color: var(--color-light);
}

.event-social a:hover {
  color: var(--color-light-highlight);
  text-decoration: none;
}

@keyframes animateHeart {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
