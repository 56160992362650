<template>
  <div class="artists">
    <VideoBanner v-if='$route.name === "Artists"' posterSrc='artist.jpg' mp4Src='artist.mp4' webmSrc='artist.webm' :overlayTitle='$COMPANY.NAME' :overlayDescription='$COMPANY.DESCRIPTION' />
    <div class="main-content small-content">
      <b-container>
        <h2>Artister &amp; Band</h2>
        <div class="artists-content page-content">
          <b-row>
            <b-col>
              <h3>Tryggt</h3>
              <p>Det ska kännas tryggt att boka artister och band. Med vår långa erfarenhet och breda kontaktnät har vi verktygen att hjälpa er med detta.</p>
              <h3>Enkelt</h3>
              <p>Genom att skicka oss en förfrågan med information om vad det är för typ av event som ni planerar samt vilken inriktning ni söker så hjälper vi gärna till med att tillgodose era önskemål och ta fram just eran artist eller band.</p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import VideoBanner from './VideoBanner'

export default {
  components: {
    VideoBanner
  }
}
</script>

<style>

</style>
