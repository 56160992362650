<template>
  <div class="particle-background">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
</template>

<script>

export default {

}
</script>

<style>
@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

.particle-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #000000;
    overflow: hidden;
}

.particle-background span {
    width: 47vmin;
    height: 47vmin;
    border-radius: 47vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 3;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.particle-background span:nth-child(0) {
    color: #07091d;
    top: 2%;
    left: 95%;
    animation-duration: 239s;
    animation-delay: -286s;
    transform-origin: -20vw 18vh;
    box-shadow: 94vmin 0 12.732709077076793vmin currentColor;
}
.background span:nth-child(1) {
    color: #07091d;
    top: 90%;
    left: 33%;
    animation-duration: 361s;
    animation-delay: -103s;
    transform-origin: 7vw 11vh;
    box-shadow: -94vmin 0 12.74736474992407vmin currentColor;
}
.particle-background span:nth-child(2) {
    color: #2b2334;
    top: 94%;
    left: 54%;
    animation-duration: 54s;
    animation-delay: -29s;
    transform-origin: -17vw -11vh;
    box-shadow: -94vmin 0 11.771490625369694vmin currentColor;
}
.particle-background span:nth-child(3) {
    color: #2b2334;
    top: 46%;
    left: 81%;
    animation-duration: 41s;
    animation-delay: -309s;
    transform-origin: 5vw -15vh;
    box-shadow: 94vmin 0 12.486149681214096vmin currentColor;
}
.particle-background span:nth-child(4) {
    color: #2b2334;
    top: 1%;
    left: 68%;
    animation-duration: 142s;
    animation-delay: -283s;
    transform-origin: -12vw 14vh;
    box-shadow: -94vmin 0 12.632045877441675vmin currentColor;
}
.particle-background span:nth-child(5) {
    color: #07091d;
    top: 88%;
    left: 67%;
    animation-duration: 148s;
    animation-delay: -454s;
    transform-origin: -21vw -12vh;
    box-shadow: 94vmin 0 12.235451125364566vmin currentColor;
}
.particle-background span:nth-child(6) {
    color: #2b2334;
    top: 54%;
    left: 85%;
    animation-duration: 205s;
    animation-delay: -336s;
    transform-origin: -14vw -9vh;
    box-shadow: 94vmin 0 12.05487873075871vmin currentColor;
}
.particle-background span:nth-child(7) {
    color: #2b2334;
    top: 25%;
    left: 20%;
    animation-duration: 299s;
    animation-delay: -392s;
    transform-origin: -13vw -15vh;
    box-shadow: -94vmin 0 12.438575473192873vmin currentColor;
}
.particle-background span:nth-child(8) {
    color: #07091d;
    top: 39%;
    left: 69%;
    animation-duration: 54s;
    animation-delay: -422s;
    transform-origin: -19vw -11vh;
    box-shadow: 94vmin 0 12.287680127220904vmin currentColor;
}
.particle-background span:nth-child(9) {
    color: #07091d;
    top: 91%;
    left: 80%;
    animation-duration: 378s;
    animation-delay: -46s;
    transform-origin: 12vw 15vh;
    box-shadow: 94vmin 0 12.26157390346599vmin currentColor;
}
.particle-background span:nth-child(10) {
    color: #07091d;
    top: 60%;
    left: 32%;
    animation-duration: 46s;
    animation-delay: -401s;
    transform-origin: 5vw 13vh;
    box-shadow: 94vmin 0 11.875145370401507vmin currentColor;
}
.particle-background span:nth-child(11) {
    color: #07091d;
    top: 58%;
    left: 55%;
    animation-duration: 388s;
    animation-delay: -92s;
    transform-origin: 15vw -14vh;
    box-shadow: -94vmin 0 12.68419636214544vmin currentColor;
}
.particle-background span:nth-child(12) {
    color: #2b2334;
    top: 50%;
    left: 47%;
    animation-duration: 320s;
    animation-delay: -411s;
    transform-origin: -17vw 18vh;
    box-shadow: 94vmin 0 12.600210108139049vmin currentColor;
}
.particle-background span:nth-child(13) {
    color: #07091d;
    top: 54%;
    left: 85%;
    animation-duration: 393s;
    animation-delay: -214s;
    transform-origin: 18vw -22vh;
    box-shadow: -94vmin 0 12.278572716295061vmin currentColor;
}
.particle-background span:nth-child(14) {
    color: #07091d;
    top: 26%;
    left: 25%;
    animation-duration: 85s;
    animation-delay: -469s;
    transform-origin: -19vw -8vh;
    box-shadow: -94vmin 0 12.213399317620182vmin currentColor;
}
.particle-background span:nth-child(15) {
    color: #07091d;
    top: 94%;
    left: 33%;
    animation-duration: 102s;
    animation-delay: -376s;
    transform-origin: 14vw 3vh;
    box-shadow: 94vmin 0 12.721286021633375vmin currentColor;
}
.particle-background span:nth-child(16) {
    color: #07091d;
    top: 85%;
    left: 47%;
    animation-duration: 320s;
    animation-delay: -399s;
    transform-origin: 13vw 22vh;
    box-shadow: 94vmin 0 11.914066077047064vmin currentColor;
}
.particle-background span:nth-child(17) {
    color: #2b2334;
    top: 19%;
    left: 52%;
    animation-duration: 288s;
    animation-delay: -183s;
    transform-origin: 17vw -7vh;
    box-shadow: 94vmin 0 12.01912341246553vmin currentColor;
}
.particle-background span:nth-child(18) {
    color: #2b2334;
    top: 90%;
    left: 1%;
    animation-duration: 395s;
    animation-delay: -209s;
    transform-origin: 21vw -1vh;
    box-shadow: 94vmin 0 11.790548751488295vmin currentColor;
}
.particle-background span:nth-child(19) {
    color: #2b2334;
    top: 72%;
    left: 39%;
    animation-duration: 74s;
    animation-delay: -342s;
    transform-origin: -9vw -5vh;
    box-shadow: 94vmin 0 12.549556284815884vmin currentColor;
}
.particle-background span:nth-child(20) {
    color: #07091d;
    top: 56%;
    left: 2%;
    animation-duration: 467s;
    animation-delay: -222s;
    transform-origin: -16vw 9vh;
    box-shadow: -94vmin 0 12.743963417885624vmin currentColor;
}
.particle-background span:nth-child(21) {
    color: #07091d;
    top: 30%;
    left: 64%;
    animation-duration: 263s;
    animation-delay: -105s;
    transform-origin: 9vw 11vh;
    box-shadow: -94vmin 0 12.725955472162408vmin currentColor;
}
.particle-background span:nth-child(22) {
    color: #07091d;
    top: 75%;
    left: 42%;
    animation-duration: 99s;
    animation-delay: -158s;
    transform-origin: -17vw 22vh;
    box-shadow: 94vmin 0 12.111030529850447vmin currentColor;
}
.particle-background span:nth-child(23) {
    color: #2b2334;
    top: 70%;
    left: 70%;
    animation-duration: 251s;
    animation-delay: -347s;
    transform-origin: -20vw -2vh;
    box-shadow: -94vmin 0 12.593922941957313vmin currentColor;
}
.particle-background span:nth-child(24) {
    color: #2b2334;
    top: 82%;
    left: 46%;
    animation-duration: 410s;
    animation-delay: -44s;
    transform-origin: -6vw 24vh;
    box-shadow: 94vmin 0 12.54868607634905vmin currentColor;
}
.particle-background span:nth-child(25) {
    color: #2b2334;
    top: 85%;
    left: 79%;
    animation-duration: 255s;
    animation-delay: -99s;
    transform-origin: 25vw -5vh;
    box-shadow: 94vmin 0 11.93975003788433vmin currentColor;
}
.particle-background span:nth-child(26) {
    color: #2b2334;
    top: 14%;
    left: 8%;
    animation-duration: 46s;
    animation-delay: -88s;
    transform-origin: -5vw -18vh;
    box-shadow: -94vmin 0 12.074474225433942vmin currentColor;
}
</style>
