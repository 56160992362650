<template>
  <div id="app">
    <Particles/>
    <Header/>
    <router-view/>
    <NewRequest/>
    <Footer/>
  </div>
</template>

<script>
import Particles from './components/Particles'
import Header from './components/Header'
import Footer from './components/Footer'
import NewRequest from './components/NewRequest'

export default {
  name: 'app',
  components: {
    Particles,
    Header,
    Footer,
    NewRequest
  },
  mounted () {
    window.fbq('track', 'PageView')
  },
  updated () {
    this.$nextTick(() => {
      if (this.$route.hash) {
        const el = document.querySelector(this.$route.hash)
        el && el.scrollIntoView()
      }
    })
  }
}
</script>

<style>
body {
  overflow-y: scroll;
  min-width: 320px;
}

#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-light);
  margin: 0;
  font-size: 16px;
  min-height: 100vh;
  background: url(./assets/bg2.jpg) top center / cover no-repeat fixed #111111;
}

:root {
  --color-dark: #000000;
  --color-light: #ffffff;
  --color-highlight: #255e96;
  --color-light-highlight: #6491be;
  --color-link: #6491be;
  --color-link-hover: #255e96;
  --color-shadow: #255e96;
  --content-width: 1200px;
  --content-width-small: 800px;
  --content-width-big: 100%;
  --color-input-placeholder: #9dc3e9;
  --input-shadow: 0px 0px 5px 2px var(--color-shadow) inset;
  --color-background-1: rgb(34, 34, 34);
  --color-background-2: rgba(34, 34, 34, 0.5);
  --color-background-2-hover: rgba(45, 45, 45, 0.8);
}

h2 {
  text-transform: uppercase;
  font-weight: 700 !important;
}

h3 {
  text-transform: uppercase;
  font-size: 1.3rem !important;
  font-weight: 700 !important;
}

h3:not(:first-child) {
  margin-top: 3rem !important;
}

a:link,
a:visited,
a:active,
a:hover,
.paragraph-link span {
  text-decoration: none;
  cursor: pointer;
  transition: all 100ms ease-in;
}

a:hover
.paragraph-link span {
  transition: all 250ms ease-in;
}

.main-content {
  color: var(--color-light);
  margin: 1rem auto;
  padding: 2rem 1rem;
  position:relative;
  bottom: 0;
  font-size: 1rem;
  max-width: var(--content-width);
  text-align: left;
  min-height: 50vh;
}

.main-content.small-content {
  max-width: var(--content-width-small);
}

.main-content.big-content {
  max-width: var(--content-width-big);
}

.page-content {
  text-align: left;
  margin-top: 2rem;
}

.main-content .last-pop {
  font-weight: 700;
  margin-top: 3rem;
}

.main-content a:link,
.main-content a:visited,
.main-content a:active,
.paragraph-link span {
  color: var(--color-link);
}

.main-content a:hover,
.paragraph-link span:hover {
  color: var(--color-link-hover);
}

/** Fix for edge visual search */
img {
  pointer-events: none;
}

.social-links img {
  width: 2rem;
  transition: all 100ms ease-in;
}

.social-links a {
  margin: 0 .5rem;
}

.social-links img:hover {
  transition: all 250ms ease-in;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.centered-text {
  text-align: center;
}

.table-responsive {
  border-radius: 0.5rem;
}

.table {
  color: var(--color-light) !important;
  background: var(--color-background-2);
  transition: all 100ms ease-in;
}

.table-hover tbody tr:hover {
  color: var(--color-light) !important;
  background-color: var(--color-background-2-hover) !important;
  transition: all 250ms ease-in;
}

.table caption {
  color: var(--color-light) !important;
}

.table th,
.table td {
  vertical-align: middle !important;
}

.table-sm th,
.table-sm td {
  padding: 0.75rem !important;
}

@media (max-width: 470px) {
  .main-content {
    padding-left: 0;
    padding-right: 0;
  }
}

</style>
