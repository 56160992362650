import VueRouter from 'vue-router'

import Home from './components/Home'
import Contact from './components/Contact'
import NewRequest from './components/NewRequest'
import About from './components/About'
import StageRental from './components/StageRental'
import Artists from './components/Artists'
import DJ from './components/DJ'
import Troubadour from './components/Troubadour'
import Coverband from './components/Coverband'
import MusicQuiz from './components/MusicQuiz'
import Events from './components/Events'
import UpcomingEvents from './components/UpcomingEvents'

export default new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/artister',
      name: 'Artists',
      component: Artists
    },
    {
      path: '/dj',
      name: 'DJ',
      component: DJ
    },
    {
      path: '/trubadur',
      name: 'Troubadour',
      component: Troubadour
    },
    {
      path: '/coverband',
      name: 'Coverband',
      component: Coverband
    },
    {
      path: '/musikquiz',
      name: 'Music Quiz',
      component: MusicQuiz
    },
    {
      path: '/events',
      name: 'Events',
      component: Events
    },
    {
      path: '/kontakt',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/om',
      name: 'About',
      component: About
    },
    {
      path: '/forfragan',
      name: 'New Request',
      component: NewRequest
    },
    {
      path: '/scen',
      name: 'Stage Rental',
      component: StageRental
    },
    {
      path: '/kommande-event',
      name: 'Upcoming Events',
      component: UpcomingEvents
    },
    {
      path: '*',
      redirect: {
        name: 'Home'
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else {
      return {
        x: 0, y: 0
      }
    }
  }
})
