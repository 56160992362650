<template>
  <div class="header">
    <div class="header-content">
      <b-navbar-brand href="#">
        <router-link to="/"><img alt="Logo" class="logo" src="../assets/logo10.png" /></router-link>
        <div class="top-buttons">
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <router-link class="top-request" to="#request" @click.native="scrollFix('#request')"><span class="top-request-text">Skicka förfrågan</span><span class="top-request-icon"><i class="fa-regular fa-pen-to-square"></i></span></router-link>
        </div>
      </b-navbar-brand>
      <b-navbar toggleable="md" type="dark">
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="">
            <b-nav-item href="#"><router-link to="/">Hem</router-link></b-nav-item>

            <b-nav-item-dropdown text="Artister" :class="{'router-link-active is-active': subIsActive(['/artister','/dj','/trubadur','/coverband','/musikquiz'])}">
              <b-dropdown-item href="#"><router-link to="/artister">Artister &amp; Band</router-link></b-dropdown-item>
              <b-dropdown-item href="#"><router-link to="/dj">DJ</router-link></b-dropdown-item>
              <b-dropdown-item href="#"><router-link to="/trubadur">Trubadur</router-link></b-dropdown-item>
              <b-dropdown-item href="#"><router-link to="/coverband">Cover/Tribute &amp; Partyband</router-link></b-dropdown-item>
              <b-dropdown-item href="#"><router-link to="/musikquiz">Musikquiz</router-link></b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item href="#"><router-link to="/events">Events</router-link></b-nav-item>
            <b-nav-item href="#"><router-link to="/scen">Scenteknik &amp; uthyrning</router-link></b-nav-item>

            <b-nav-item href="#"><router-link to="/kommande-event">Kommande Event</router-link></b-nav-item>
            <b-nav-item href="#"><router-link to="/kontakt">Kontakt</router-link></b-nav-item>
            <b-nav-item href="#"><router-link to="/om">Om</router-link></b-nav-item>

          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  mounted: function () {
    setTimeout(() => this.scrollFix(this.$route.hash), 1)
  },
  methods: {

    scrollFix: function (hashbang) {
      console.log(hashbang)
      if (location.hash === hashbang) {
        location.hash = ''
      }
      location.hash = hashbang
    },

    subIsActive (input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    }

  }
}
</script>

<style>

hr {
  margin-top: 0;
}

.header {
  -webkit-box-shadow: 0px 0px 2px 0px var(--color-dark);
  -moz-box-shadow: 0px 0px 2px 0px var(--color-dark);
  box-shadow: 0px 0px 2px 0px var(--color-dark);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: var(--color-dark);
}

.header-content {
  max-width: var(--content-width);
  width: auto;
  margin: auto;
}

.navbar {
  font-weight: 500;
  padding-top: 0 !important;
}

.navbar a:link,
.navbar a:visited,
.navbar a:active,
.navbar a:hover {
  text-decoration: none;
  color: var(--color-light);
}

.navbar-brand {
  padding: 0 1rem 0.5rem 1rem !important;
  min-width: 11rem;
  width: 100%;
  margin-right: 0 !important;
  display: flex !important;
}

.navbar-brand a {
  width: -webkit-fill-available;
  display: flex;
}

.logo {
  max-width: 17rem;
  margin-top: 1rem;
  width: 100%;
  align-self: flex-start;
}

.navbar .nav-item {
  text-transform: uppercase;
  text-align: left;
}

.nav-link,
.dropdown-item {
  color: var(--color-light) !important;
}

.nav-link a:hover,
.nav-link:hover {
  text-decoration: none;
  color: var(--color-light-highlight) !important;
}

.header a.is-active,
.header .b-nav-dropdown.is-active a.nav-link {
  color: var(--color-highlight) !important;
  text-decoration: underline;
}

.dropdown-menu {
  background: var(--color-dark) !important;
  text-transform: capitalize;
}

.dropdown-item:hover {
  background: var(--color-highlight) !important;
  color: var(--color-dark) !important;
}

.navbar-toggler {
  color: rgba(255, 255, 255, 0.8) !important;
  border-color: rgba(255, 255, 255, 0.8) !important;
  border-radius: 0.5rem !important;
  padding: 0.15rem !important;
  margin: 1rem 0rem 0rem 1rem;
  position: relative;
  align-self: flex-end;
  top: 0;
  right: 0;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.navbar .nav-item:first-child .nav-link {
  padding-left: 0 !important;
}

.top-buttons {
    display: flex;
    justify-content: right;
    flex-direction: column;
}

.top-request {
  margin: 1rem 0rem 0 1rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--color-light);
  font-size: 0.8rem;
  border-radius: 0.5rem;
  color: var(--color-light);
  font-weight: 700;
  align-self: flex-end;
}

a:link.top-request,
a:visited.top-request,
a:active.top-request {
  color: var(--color-light) !important;
  text-decoration: none;
}

a:hover.top-request {
  color: var(--color-light);
  background: var(--color-link);
  text-decoration: none;
}

.top-request-icon {
  padding: 0 0.3rem;
}

@media (max-width: 470px) {
  .top-request-text {
    display: none;
  }
  .top-request {
    border: none;
    padding: 0;
    font-size: 2rem;
    margin-top: 0.2rem;
  }
  a:hover.top-request {
  color: var(--color-link);
  background: none;
  text-decoration: none;
}
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
  .header-content {
    width: fit-content;
  }
  .navbar {
    position: relative;
  }
  .navbar-brand {
    width: 100%;
  }
  .navbar-toggler {
    display: none;
  }
  .top-request {
    padding: 0.5rem;
    font-size: 1rem;
  }
}
</style>
