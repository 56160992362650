<template>
  <div class="events">
    <VideoBanner v-if='$route.name === "Events"' posterSrc='party.jpg' mp4Src='party.mp4' webmSrc='party.webm' :overlayTitle='$COMPANY.NAME' :overlayDescription='$COMPANY.DESCRIPTION' />
    <div class="main-content small-content">
      <b-container>
        <h2>Events</h2>
        <div class="events-content page-content">
          <b-row>
            <b-col>
              <h3>Företagsevent</h3>
              <p>Vi erbjuder underhållning, aktiviteter, catering samt all teknik som behövs till ett företagsevent.</p>
              <p>Vi har även tält, bord, stolar och scener.</p>
              <p>Vi erbjuder även helhetslösningar för privata fester.</p>
              <p>Vår erfarenhet och bredd gör att vi kan hjälpa er med allt som hör till eventet eller företagsfesten.</p>
              <p>Hör av dig till oss för en förutsättningslös diskussion om hur vi kan bli din eventpartner.</p>
              <h3>Middagsunderhållning</h3>
              <p>Proffsig underhållning till konferensmiddagar, storslagna galor och små privata tillställningar.</p>
              <p>Musik och underhållning under hela middagen från fördrinken till kaffet.</p>
              <p>Krydda festen med en DJ eller ett partyband efter kaffet så kan ni dansa fram till småtimmarna.</p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import VideoBanner from './VideoBanner'

export default {
  components: {
    VideoBanner
  }
}
</script>

<style>

</style>
