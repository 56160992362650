<template>
  <div class="contact">
    <VideoBanner :v-if='$route.name === "Contact"' posterSrc='contact.jpg' mp4Src='contact.mp4' webmSrc='contact.webm' :overlayTitle='$COMPANY.NAME' :overlayDescription='$COMPANY.DESCRIPTION' />
    <div class="main-content small-content">
      <b-container class="contact-info">
        <h2>Kontakt</h2>
        <div class="contact-info-content">
          <b-row>
            <b-col cols="12" sm="6">
              <p class="contact-email">E-POST:<br /><a v-bind:href="'mailto:' + $COMPANY.EMAIL" target="_blank">{{$COMPANY.EMAIL}}</a><br /></p>
            </b-col>
            <b-col cols="12" sm="6">
              <p class="contact-phone">TELEFON:<br /><a v-bind:href="'tel:' + $COMPANY.PHONE1.CALL">{{$COMPANY.PHONE1.DISPLAY}}</a></p>
            </b-col>
            <!-- <b-col>
              <p class="contact-request"><router-link to="/request">Skicka en förfrågan >></router-link></p>
            </b-col> -->
          </b-row>
          <b-row class="contact-info-content">
            <b-col cols="12">
              <p class="contact-social">SOCIALA MEDIER:<br /></p>
              <div class="social-links">
                <a v-bind:href="$COMPANY.SOCIAL.FB" target="_blank"><img src="../assets/fb-logo.png" alt="Facebook"/></a>
                <a v-bind:href="$COMPANY.SOCIAL.IG" target="_blank"><img src="../assets/ig-logo.png" alt="Instagram"/></a>
                <a v-bind:href="$COMPANY.SOCIAL.TIKTOK" target="_blank"><img src="../assets/tiktok-logo.png" alt="TikTok "/></a>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import VideoBanner from './VideoBanner'

export default {
  components: {
    VideoBanner
  }
}
</script>

<style>
.contact-info-content {
  text-align: left;
  margin-top: 2rem;
}

.contact-request {
  font-size: 1.5rem;
}

.profile-image {
  border: 2px solid var(--color-light);
  max-width: 200px;
  margin: 0 auto 1rem;
  border-radius: 2rem;
}

.contact-info p {
  margin-top: 0;
  margin-bottom: 0;
}

.contact-info .col {
  margin-bottom: 2rem;
}

.contact-email,
.contact-phone,
.contact-social {
  font-size: 1rem;
}

.contact-email a,
.contact-phone a,
.contact-social a {
  font-size: 1.5rem;
}

.contact-social {
  padding-bottom: 0.5rem;
}

.social-links a:first-child {
  margin-left: 0;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}
</style>
