<template>
  <div class="upcoming-events">
    <VideoBanner v-if='$route.name === "Upcoming Events"' posterSrc='upcoming.jpg' mp4Src='upcoming.mp4' webmSrc='upcoming.webm' :overlayTitle='$COMPANY.NAME' :overlayDescription='$COMPANY.DESCRIPTION' />
    <div class="main-content">
      <b-container>
        <h2>Kommande Event</h2>
        <div class="upcoming-events-content page-content">
          <p>Denna sida uppdateras kontinuerligt så håll utkik efter nya event!</p>
          <b-table-simple hover small caption-top responsive>
            <!-- <caption>Denna sida uppdateras kontinuerligt så håll utkik efter nya event!</caption> -->
            <b-thead head-variant="dark">
              <b-tr>
                <b-th>Datum</b-th>
                <b-th>Beskrivning</b-th>
                <b-th>Plats</b-th>
                <b-th></b-th>
                <b-th>Stad</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="event in upcomingEvents" :key="event.id">
                <b-td>{{trimDate(event.date)}}</b-td>
                <b-td>{{event.description}}</b-td>
                <b-td v-if="event.link !== '' && event.link !== undefined && event.link !== null">
                  <a :href="event.link" target="_blank">{{event.location}}</a>
                </b-td>
                <b-td v-else>{{event.location}}</b-td>
                <b-td class="event-social">
                  <span class="event-map event-item">
                    <a v-if="event.map !== '' && event.map !== undefined && event.map !== null" :href="event.map" target="_blank"><i class="fa-solid fa-map-location-dot"></i></a>
                  </span>
                  <span class="event-fb event-item">
                    <a v-if="event.fb !== '' && event.fb !== undefined && event.fb !== null" :href="event.fb" target="_blank"><i class="fa-brands fa-facebook-square"></i></a>
                  </span>
                  <span class="event-ig event-item">
                    <a v-if="event.ig !== '' && event.ig !== undefined && event.ig !== null" :href="event.ig" target="_blank"><i class="fa-brands fa-instagram"></i></a>
                  </span>
                </b-td>
                <b-td>{{event.city}}</b-td>
                <b-td><a v-if="event.booking !== '' && event.booking !== undefined && event.booking !== null" :href="event.booking" target="_blank">Boka</a></b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import VideoBanner from './VideoBanner'
import EventsService from '../sevices/EventsService'

const eventsService = new EventsService()

export default {
  components: {
    VideoBanner
  },
  methods: {
    trimDate (date) {
      return eventsService.trimDate(date)
    }
  },
  data () {
    return {
      events: eventsService.getAllEvents(),
      upcomingEvents: eventsService.getUpcomingEvents()
    }
  }
}
</script>

<style>
.event-social {
  text-align: center;
  font-size: 1.2rem;
}

.event-item {
  display: inline-block;
  width: 1.5rem;
  margin: 0.2rem;
}

.event-social a:link,
.event-social a:visited,
.event-social a:active {
  color: var(--color-light);
}

.event-social a:hover {
  color: var(--color-light-highlight);
  text-decoration: none;
}
</style>
