<template>
  <div class="about">
    <VideoBanner v-if='$route.name === "About"' posterSrc='startsida.jpg' mp4Src='startsida.mp4' webmSrc='startsida.webm' :overlayTitle='$COMPANY.NAME' :overlayDescription='$COMPANY.DESCRIPTION' />
    <div class="main-content small-content">
      <b-container>
        <h2>Om SWE Event &amp; Artist</h2>
        <div class="about-content page-content">
          <b-row>
            <b-col>
              <p>SWE Event &amp; Artist har funnits sedan 2017.</p>
              <p>Företaget som tar hand om artister, underhållning till erat event.</p>
              <p>Vi hjälper er med att boka artister och skräddarsyr underhållning till era arrangemang. Både som företag eller privat person skall anordna ett event.</p>
              <p>Våra tjänster innebär underhållningsplanering till företagsevent, företagsfest, personalfest. Ljud- och ljusanläggningar samt all annan teknik och transporter m.m.</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h3>SWE Event &amp; Artist</h3>
              <p>Företagsevent, eventkonsult, artistförmedling och artistmanagement</p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import VideoBanner from './VideoBanner'

export default {
  components: {
    VideoBanner
  }
}
</script>

<style>
.contact-request {
  font-size: 1.5rem;
}
</style>
