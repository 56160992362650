<template>
  <div class="home">
    <VideoBanner :v-if='$route.name === "Home"' posterSrc='startsida.jpg' mp4Src='startsida.mp4' webmSrc='startsida.webm' :overlayTitle='$COMPANY.NAME' :overlayDescription='$COMPANY.DESCRIPTION' />
    <div class="main-content big-content">
      <b-container>
        <div class="home-content page-content">
          <Splash/>
          <div class="page-grid">
            <div class="grid-image-container">
              <router-link to="/artister" class="grid-image"><img src="../assets/images/home/artist.jpg"/></router-link>
              <span class="grid-image-overlay">Artister &amp; Band</span>
            </div>
            <div class="grid-image-container">
              <router-link to="/dj" class="grid-image"><img src="../assets/images/home/dj.jpg"/></router-link>
              <span class="grid-image-overlay">DJ</span>
            </div>
            <div class="grid-image-container">
              <router-link to="/trubadur" class="grid-image"><img src="../assets/images/home/trubadur.jpg"/></router-link>
              <span class="grid-image-overlay">Trubadur</span>
            </div>
            <div class="grid-image-container">
              <router-link to="/coverband" class="grid-image"><img src="../assets/images/home/band.jpg"/></router-link>
              <span class="grid-image-overlay">Cover/Tribute &amp; Partyband</span>
            </div>
            <div class="grid-image-container">
              <router-link to="/musikquiz" class="grid-image"><img src="../assets/images/home/musikquiz.jpg"/></router-link>
              <span class="grid-image-overlay">Musikquiz</span>
            </div>
            <div class="grid-image-container">
              <router-link to="/events" class="grid-image"><img src="../assets/images/home/events.jpg"/></router-link>
              <span class="grid-image-overlay">Events</span>
            </div>
            <div class="grid-image-container">
              <router-link to="/scen" class="grid-image"><img src="../assets/images/home/scen.jpg"/></router-link>
              <span class="grid-image-overlay">Scenteknik &amp; Uthyrning</span>
            </div>
            <div class="grid-image-container">
              <router-link to="/kommande-event" class="grid-image"><img src="../assets/images/home/kommande.jpg"/></router-link>
              <span class="grid-image-overlay">Kommande Event</span>
            </div>
            <div class="grid-image-container">
              <router-link to="/kontakt" class="grid-image"><img src="../assets/images/home/kontakt.jpg"/></router-link>
              <span class="grid-image-overlay">Kontakt</span>
            </div>
            <div class="grid-image-container">
              <router-link to="/om" class="grid-image"><img src="../assets/images/home/om.jpg"/></router-link>
              <span class="grid-image-overlay">Om SWE Event &amp; Artist</span>
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import VideoBanner from './VideoBanner'
import Splash from './Splash'

export default {
  components: {
    VideoBanner,
    Splash
  },
  data () {
    return {
    }
  }
}
</script>

<style>
.home .container {
  max-width: fit-content !important;
  width: fit-content !important;
}
.home-content {
  margin-top: 0;
}

.home .main-content {
  padding-top: 0;
  padding-bottom: 0;
}

.page-grid {
  margin: 0rem auto;
  display: flex;
  flex-wrap: wrap ;
  position: relative;
  justify-content: center;
}

.grid-image-container {
  width: calc(33% - 40px);
  margin: 20px;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  transition: 1s ease-in-out;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  justify-content: center;
}

.grid-image {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  background: #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  overflow: hidden;
}

.grid-image img {
  width: 100%;
  height: 100%;
  transition: 1s ease-in-out;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
}

.grid-image-container:hover {
  -ms-transform: scale(1.1,1.1) rotate(-0deg); /* IE 9 */
  -webkit-transform: scale(1.1,1.1) rotate(-0deg); /* Safari */
  transform: scale(1.1,1.1) rotate(-0deg);
  filter: contrast(130%) saturate(1.5) blur(0px);
  z-index: 999;
}

.grid-image-container:hover > .grid-image img {
  -ms-transform: scale(1.1,1.1) rotate(-5deg); /* IE 9 */
  -webkit-transform: scale(1.1,1.1) rotate(-5deg); /* Safari */
  transform: scale(1.1,1.1) rotate(-5deg);
}

.grid-image-overlay {
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 0.5rem;
  bottom: 5%;
  left: 0;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.3rem;
  text-shadow: 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000;
}

@media (min-width: 1440px) {
  .grid-image-container {
    width: calc(25% - 40px);
  }
}

@media (max-width: 992px) {
  .grid-image-container {
    width: calc(50% - 40px);
  }
  .home .container {
    max-width: fit-content !important;
  }
}

@media (max-width: 600px) {
  .grid-image-container {
    width: calc(100% - 40px);
  }
}
</style>
