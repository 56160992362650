<template>
  <div class="stage">
    <VideoBanner v-if='$route.name === "Stage Rental"' posterSrc='scen.jpg' mp4Src='scen.mp4' webmSrc='scen.webm' :overlayTitle='$COMPANY.NAME' :overlayDescription='$COMPANY.DESCRIPTION' />
    <div class="main-content small-content">
      <b-container>
        <h2>Scenteknik &amp; uthyrning</h2>
        <div class="stage-content page-content">
          <b-row>
            <b-col>
              <h3>Helhetslösning till alla typer av events</h3>
              <p>
                Vi har ett nära samarbete med bra leverantörer med flera olika leverantörer av ljud, ljus och scenanläggning.
                Oavsett om du planerar en fest, bröllop, student, kick-off, mässmonter, event eller konsert,
                tillhandahåller vi utrustning och gediget kunnande för ett lyckat evenemang.
              </p>
              <p>
                Tillsammans med våra leverantörer så har vi mångårig erfarenhet inom området ljud, ljus och scenteknik
                erbjuder vi totallösningar till alla typer av events. T.ex. konserter, företagspresentationer, mässor,
                teatrar &amp; nattklubbar samt privata nöjesarrangemang.
              </p>
              <p>
                Ljud &amp; ljus är en av de viktigaste delarna under ett arrangemang oavsett om det är en liten
                tillställning eller en stor konsert, konferens eller liknande.
              </p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import VideoBanner from './VideoBanner'

export default {
  components: {
    VideoBanner
  }
}
</script>

<style>

</style>
