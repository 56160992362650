<template>
  <div class="video-container" id="video-container">
    <video :poster="require('@/assets/video/' + posterSrc + '')" class="top-video" ref="topvideo" autoplay muted loop disableRemotePlayback playsinline>
      <source :src="require('@/assets/video/' + mp4Src + '')" type="video/mp4">
      <source :src="require('@/assets/video/' + webmSrc + '')" type="video/webm">
      Your browser does not support the video tag.
    </video>
    <div class="video-overlay">
      <div class="video-overlay-content">
        <h2 class="video-overlay-title">{{overlayTitle}}</h2>
        <p class="video-overlay-description">{{overlayDescription}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['posterSrc', 'mp4Src', 'webmSrc', 'overlayTitle', 'overlayDescription'],
  components: {

  }
}
</script>

<style>
  .video-container {
    width: 100%;
    overflow: hidden;
    max-height: 60vh;
    position: relative;
  }

  .top-video {
    width: 100%;
    position: relative;
    -o-filter: blur(2px);
    filter: blur(2px);
    transform: scale(1.1);
  }

  video::-internal-media-controls-overlay-cast-button {
    display: none;
  }

  .video-overlay {
    box-sizing: border-box;
    padding: 1rem;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #FFFFFF;
    text-shadow: 0 0 5px #fff, 0 0 10px #000, 0 0 15px #000, 0 0 20px var(--color-shadow), 0 0 30px var(--color-shadow), 0 0 40px var(--color-shadow), 0 0 55px var(--color-shadow), 0 0 75px var(--color-shadow);
    opacity: 1;
  }

  .video-overlay-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }

  .video-overlay-content h2 {
    width: 100%;
  }

  .video-overlay-content p {
    width: 100%;
  }

  .video-overlay-content p:last-child {
    margin-bottom: 0;
  }

   @media (min-width: 576px) {
    .video-overlay h2 {
      font-size: 3rem;
    }
    .video-overlay-content p {
      font-size: 1.5rem;
    }
  }

  @media (min-width: 768px) {
    .video-overlay h2 {
      font-size: 3.5rem;
    }
    .video-overlay-content p {
      font-size: 2rem;
    }
    .top-video {
      -o-filter: blur(3px);
      filter: blur(3px);
    }
  }

  @media (min-width: 992px) {
    .top-video {
      -o-filter: blur(4px);
      filter: blur(4px);
    }
  }

  @media (min-width: 1200px) {
    .top-video {
      -o-filter: blur(5px);
      filter: blur(5px);
    }
  }

  @media (min-width: 1400px) {
    .top-video {
      -o-filter: blur(6px);
      filter: blur(6px);
    }
  }
</style>
