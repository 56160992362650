<template>
  <div class="music-quiz">
    <VideoBanner :v-if='$route.name === "Music Quiz"' posterSrc='musikquiz.jpg' mp4Src='musikquiz.mp4' webmSrc='musikquiz.webm' :overlayTitle='$COMPANY.NAME' :overlayDescription='$COMPANY.DESCRIPTION' />
    <div class="main-content small-content">
      <b-container>
        <h2>Musikquiz</h2>
        <div class="music-quiz-content page-content">
          <b-row>
            <b-col>
              <h3>Älskar du frågesport? Då är detta något för dig.</h3>
              <p>Testa dina talanger i musikens värld.</p>
              <p>Här skippar vi papper och penna och äntrar den digitala tidsåldern. Självklart finns möjligheten om man hellre önskar att köra ett Musikquiz med penna och papper.</p>
              <p class="last-pop">Kontakta oss för mer information!</p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import VideoBanner from './VideoBanner'

export default {
  components: {
    VideoBanner
  }
}
</script>

<style>

</style>
