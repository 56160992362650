<template>
  <div class="dj">
    <VideoBanner v-if='$route.name === "DJ"' posterSrc='dj.jpg' mp4Src='dj.mp4' webmSrc='dj.webm' :overlayTitle='$COMPANY.NAME' :overlayDescription='$COMPANY.DESCRIPTION' />
    <div class="main-content small-content">
      <b-container>
        <h2>DJ</h2>
        <div class="dj-content page-content">
          <b-row>
            <b-col>
              <h3>Vi löser tekniken och musiken</h3>
              <p>En proffsig DJ kan göra skillnaden på ett lyckat event</p>
              <p>Boka en Professionell DJ till festen, bröllopet eller företagsevenemanget. Vi hjälper er med allt från musik till ljud och ljus.</p>
              <h3>DJ för alla tillfällen</h3>
              <p>Det finns DJ's för alla tillfällen! Repertoaren anpassas efter publiken &amp; era önskemål.</p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import VideoBanner from './VideoBanner'

export default {
  components: {
    VideoBanner
  }
}
</script>

<style>

</style>
