export default class EventService {
  eventsObjects = [
    {
      id: 1,
      date: '2022-05-04T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Austin Food Works',
      city: 'Stockholm',
      link: 'https://austinfoodworks.se/',
      fb: 'https://www.facebook.com/AustinFoodWorks',
      ig: 'https://www.instagram.com/austinfoodworks/',
      map: 'https://www.google.com/maps/place/Austin+Food+Works/@59.3479244,18.0440595,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d73d17dc1f3:0xe3b435eafafb24a2!8m2!3d59.3479242!4d18.04625',
      booking: 'https://austinfoodworks.se/book-a-table'
    },
    {
      id: 2,
      date: '2022-04-20T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Austin Food Works',
      city: 'Stockholm',
      link: 'https://austinfoodworks.se/',
      fb: 'https://www.facebook.com/AustinFoodWorks',
      ig: 'https://www.instagram.com/austinfoodworks/',
      map: 'https://www.google.com/maps/place/Austin+Food+Works/@59.3479244,18.0440595,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d73d17dc1f3:0xe3b435eafafb24a2!8m2!3d59.3479242!4d18.04625',
      booking: 'https://austinfoodworks.se/book-a-table'
    },
    {
      id: 3,
      date: '2022-05-19T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Austin Food Works',
      city: 'Stockholm',
      link: 'https://austinfoodworks.se/',
      fb: 'https://www.facebook.com/AustinFoodWorks',
      ig: 'https://www.instagram.com/austinfoodworks/',
      map: 'https://www.google.com/maps/place/Austin+Food+Works/@59.3479244,18.0440595,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d73d17dc1f3:0xe3b435eafafb24a2!8m2!3d59.3479242!4d18.04625',
      booking: 'https://austinfoodworks.se/book-a-table'
    },
    {
      id: 4,
      date: '2022-06-02T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Austin Food Works',
      city: 'Stockholm',
      link: 'https://austinfoodworks.se/',
      fb: 'https://www.facebook.com/AustinFoodWorks',
      ig: 'https://www.instagram.com/austinfoodworks/',
      map: 'https://www.google.com/maps/place/Austin+Food+Works/@59.3479244,18.0440595,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d73d17dc1f3:0xe3b435eafafb24a2!8m2!3d59.3479242!4d18.04625',
      booking: 'https://austinfoodworks.se/book-a-table'
    },
    {
      id: 5,
      date: '2022-05-26T00:00:00.000Z',
      description: 'Trubadur: Mats Granberg',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 6,
      date: '2022-06-09T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 7,
      date: '2022-06-16T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 9,
      date: '2022-06-30T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 10,
      date: '2022-07-07T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 11,
      date: '2022-07-14T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 12,
      date: '2022-07-21T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 13,
      date: '2022-07-28T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 14,
      date: '2022-08-04T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 15,
      date: '2022-08-11T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 16,
      date: '2022-08-18T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 17,
      date: '2022-08-20T00:00:00.000Z',
      description: 'Liveduon',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 18,
      date: '2022-09-10T00:00:00.000Z',
      description: 'Liveduon',
      location: 'Café Villa Ed',
      city: 'Upplands Väsby',
      link: 'https://www.villaed.com/',
      fb: 'https://www.facebook.com/cafevillaed',
      map: 'https://g.page/cafevillaed?share'
    },
    {
      id: 19,
      date: '2022-10-20T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Austin Food Works',
      city: 'Stockholm',
      link: 'https://austinfoodworks.se/',
      fb: 'https://www.facebook.com/AustinFoodWorks',
      ig: 'https://www.instagram.com/austinfoodworks/',
      map: 'https://www.google.com/maps/place/Austin+Food+Works/@59.3479244,18.0440595,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d73d17dc1f3:0xe3b435eafafb24a2!8m2!3d59.3479242!4d18.04625',
      booking: 'https://austinfoodworks.se/book-a-table'
    },
    {
      id: 20,
      date: '2022-11-03T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Austin Food Works',
      city: 'Stockholm',
      link: 'https://austinfoodworks.se/',
      fb: 'https://www.facebook.com/AustinFoodWorks',
      ig: 'https://www.instagram.com/austinfoodworks/',
      map: 'https://www.google.com/maps/place/Austin+Food+Works/@59.3479244,18.0440595,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d73d17dc1f3:0xe3b435eafafb24a2!8m2!3d59.3479242!4d18.04625',
      booking: 'https://austinfoodworks.se/book-a-table'
    },
    {
      id: 21,
      date: '2022-11-17T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Austin Food Works',
      city: 'Stockholm',
      link: 'https://austinfoodworks.se/',
      fb: 'https://www.facebook.com/AustinFoodWorks',
      ig: 'https://www.instagram.com/austinfoodworks/',
      map: 'https://www.google.com/maps/place/Austin+Food+Works/@59.3479244,18.0440595,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d73d17dc1f3:0xe3b435eafafb24a2!8m2!3d59.3479242!4d18.04625',
      booking: 'https://austinfoodworks.se/book-a-table'
    },
    {
      id: 22,
      date: '2022-12-01T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Austin Food Works',
      city: 'Stockholm',
      link: 'https://austinfoodworks.se/',
      fb: 'https://www.facebook.com/AustinFoodWorks',
      ig: 'https://www.instagram.com/austinfoodworks/',
      map: 'https://www.google.com/maps/place/Austin+Food+Works/@59.3479244,18.0440595,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d73d17dc1f3:0xe3b435eafafb24a2!8m2!3d59.3479242!4d18.04625',
      booking: 'https://austinfoodworks.se/book-a-table'
    },
    {
      id: 23,
      date: '2022-12-15T00:00:00.000Z',
      description: 'Musikquiz',
      location: 'Austin Food Works',
      city: 'Stockholm',
      link: 'https://austinfoodworks.se/',
      fb: 'https://www.facebook.com/AustinFoodWorks',
      ig: 'https://www.instagram.com/austinfoodworks/',
      map: 'https://www.google.com/maps/place/Austin+Food+Works/@59.3479244,18.0440595,17z/data=!3m1!4b1!4m5!3m4!1s0x465f9d73d17dc1f3:0xe3b435eafafb24a2!8m2!3d59.3479242!4d18.04625',
      booking: 'https://austinfoodworks.se/book-a-table'
    }
  ]

  getAllEvents () {
    return this.eventsObjects
    // Use vue-resource or any other http library to send your request
  }

  sortEventsByDate (events) {
    return events.sort((a, b) => {
      let da = new Date(a.date)
      let db = new Date(b.date)
      return da - db
    })
  }

  filterEvents (events) {
    return events.filter(e => {
      const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
      const eventDate = new Date(e.date).getTime()
      return eventDate > yesterday ? e : null
    })
  }

  trimDate (date) {
    return date !== null ? date.split('T')[0] : null
  }

  getUpcomingEvents () {
    return this.sortEventsByDate(this.filterEvents(this.getAllEvents()))
  }

  getNextEvent () {
    return this.getUpcomingEvents()[0]
  }
}
