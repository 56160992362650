<template>
  <div class="request-container" id="request">
    <div class="request main-content" :class="{ 'small-content' : formSent}">
      <b-container v-if="!formSent" class="request-info">
        <h2 class="centered-text">Skicka en förfrågan!</h2>
        <form v-if="!formSent" v-on:submit.prevent="submitForm" class="request-form" id="request-form" autocomplete="on">
        <b-form-row @keydown.enter.stop>
          <b-col cols="12" sm="6" md="3">
            <input @blur="inputHasChanged($event)" type="text" id="firstname" name="firstname" ref="firstname" v-model="form.firstname" placeholder="Förnamn" required minlength="2" maxlength="100">
          </b-col>
          <b-col cols="12" sm="6" md="3">
            <input @blur="inputHasChanged($event)" type="text" id="lastname" name="lastname" ref="lastname" v-model="form.lastname" placeholder="Efternamn" required minlength="2" maxlength="100">
          </b-col>
          <b-col cols="12" sm="6" md="3">
            <input @blur="inputHasChanged($event)" type="email" id="email" name="email" ref="email" v-model="form.email" placeholder="E-post" required maxlength="250">
          </b-col>
          <b-col  cols="12" sm="6" md="3">
            <input @blur="inputHasChanged($event)" type="text" id="tel" name="tel" ref="tel" v-model="form.tel" placeholder="Telefon" required minlength="7" maxlength="30">
          </b-col>
        </b-form-row>
        <b-form-row @keydown.enter.stop>
          <b-col cols="12" sm="6" md="3">
            <select @blur="inputHasChanged($event)" id="privcom" name="privcom" ref="privcom" v-model="form.privcom" required>
              <option value disabled selected>Privat eller företag</option>
              <option value="Företag">Företag</option>
              <option value="Privat">Privat</option>
            </select>
          </b-col>
          <b-col cols="12" sm="6" md="3">
            <input type="text" id="organization" name="organization" ref="organization" v-model="form.organization" placeholder="Företagsnamn" maxlength="100">
          </b-col>
          <b-col cols="12" sm="6" md="3">
            <input type="text" id="eventcity" name="eventcity" ref="eventcity" v-model="form.eventcity" placeholder="Stad/Ort där eventet ska ske" maxlength="100">
          </b-col>
          <b-col cols="12" sm="6" md="3">
            <input type="text" id="guests" name="guests" ref="guests" v-model="form.guests" placeholder="Ca. antal gäster" maxlength="100">
          </b-col>
        </b-form-row>
        <b-form-row @keydown.enter.stop>
          <b-col cols="12" sm="6" md="3">
            <input @blur="inputHasChanged($event)" type="date" id="date" name="date" ref="date" v-model="form.date" :min="getTomorrow()" required>
          </b-col>
          <b-col cols="12" sm="6" md="9">
            <input @blur="inputHasChanged($event)" type="text" id="shortdesc" name="shortdesc" ref="shortdesc" v-model="form.shortdesc" minlength="5" maxlength="250" required placeholder="Typ av event (fest, konferens, resa, aktivitet)">
          </b-col>
        </b-form-row>
        <b-form-row @keydown.enter.stop>
          <b-col>
              <textarea @blur="inputHasChanged($event)" @keyup="charCount()" id="description" name="description" ref="description" v-model="form.description" required minlength="5" :maxlength="maxCharacters" multiline="true" rows="6" placeholder="Beskriv vad du önskar få offert på.."></textarea>
              <p class="total-characters">{{totalCharacters}}/{{maxCharacters}}</p>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col class="centered-text">
            <vue-recaptcha class="recaptcha-style" @verify="onVerify" sitekey="6LfNi4UfAAAAABSI42hsH0F43xoKHCbpPlIKK3E8" ref="recaptcha" language="sv_SE" theme="dark" size="normal"></vue-recaptcha>
            <input type="submit" id="submit" value="Skicka">
          </b-col>
        </b-form-row>
        </form>
      </b-container>
      <b-container v-if="formSent">
        <div class="thankyou-content page-content">
          <h2 class="centered-text">Tack för din förfrågan!</h2>
          <p>Din förfrågan är nu mottagen och behandlas så snart som möjligt. Du kommer att bli kontaktad inom kort.</p>
          <p class="another-request paragraph-link" v-on:click="anotherRequest"><span>Skicka en till förfrågan >></span></p>
          <h3>Du har skickat in följande uppgifter:</h3>
          <p>
            <b>Namn:</b> {{formConfirmationValues.firstname}} {{formConfirmationValues.lastname}}<br>
            <b>E-post:</b> {{formConfirmationValues.email}}<br>
            <b>Telefon:</b> {{formConfirmationValues.tel}}<br>
            <b>Privat eller företag:</b> {{formConfirmationValues.privcom}}<br>
            <b>Företag:</b> {{formConfirmationValues.organization}}<br>
            <b>Stad/Ort:</b> {{formConfirmationValues.eventcity}}<br>
            <b>Antal gäster:</b> {{formConfirmationValues.guests}}<br>
            <b>Datum:</b> {{formConfirmationValues.date}}<br>
            <b>Typ av event:</b> {{formConfirmationValues.shortdesc}}<br>
          </p>
          <h3>Beskrivning:</h3>
          <p class="multiline-confirmation">{{formConfirmationValues.description}}</p>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha
  },
  data: () => {
    return {
      formSent: false,
      maxCharacters: 1000,
      tomorrow: '',
      form: {
        firstname: '',
        lastname: '',
        email: '',
        tel: '',
        privcom: '',
        organization: '',
        eventcity: '',
        guests: '',
        date: '',
        shortdesc: '',
        description: ''
      },
      formConfirmationValues: {
        firstname: '',
        lastname: '',
        email: '',
        tel: '',
        privcom: '',
        organization: '',
        eventcity: '',
        guests: '',
        date: '',
        shortdesc: '',
        description: ''
      },
      totalCharacters: 0
    }
  },
  methods: {
    getTomorrow () {
      let tomorrow = new Date()
      tomorrow = tomorrow.toISOString(tomorrow.setDate(tomorrow.getDate() + 1)).split('T')[0]
      return tomorrow
    },
    inputHasChanged (event) {
      event.target.classList.add('haschanged')
    },
    submitForm (event) {
      if (this.form.robot) {
        axios.post('https://sweea.se/api/SendEmail.php', this.form).then((res) => { // Change this URL before going live
          // Success
          this.showThankYouMessage()
        }).catch((error) => {
          // alert(error)
          console.log(error)
        }).finally(() => {
          // Perform action in always
        })
      }
    },
    onVerify: function (response) {
      if (response) this.form.robot = true
    },
    charCount () {
      this.totalCharacters = this.form.description.length
    },
    resetFormInput () {
      this.form.firstname = ''
      this.form.lastname = ''
      this.form.email = ''
      this.form.tel = ''
      this.form.privcom = ''
      this.form.organization = ''
      this.form.eventcity = ''
      this.form.guests = ''
      this.form.date = ''
      this.form.shortdesc = ''
      this.form.description = ''
      this.totalCharacters = 0
      for (const item in this.$refs) {
        if (this.$refs[item].classList !== undefined) {
          this.$refs[item].classList.remove('haschanged')
        }
      }
    },
    showThankYouMessage () {
      this.formSent = true
      window.fbq('track', 'Lead')
      this.formConfirmationValues.firstname = this.form.firstname
      this.formConfirmationValues.lastname = this.form.lastname
      this.formConfirmationValues.email = this.form.email
      this.formConfirmationValues.tel = this.form.tel
      this.formConfirmationValues.privcom = this.form.privcom
      this.formConfirmationValues.organization = this.form.organization
      this.formConfirmationValues.eventcity = this.form.eventcity
      this.formConfirmationValues.guests = this.form.guests
      this.formConfirmationValues.date = this.form.date
      this.formConfirmationValues.shortdesc = this.form.shortdesc
      this.formConfirmationValues.description = this.form.description
      this.resetFormInput()
    },
    anotherRequest () {
      this.formSent = false
      this.formConfirmationValues.firstname = ''
      this.formConfirmationValues.lastname = ''
      this.formConfirmationValues.email = ''
      this.formConfirmationValues.tel = ''
      this.formConfirmationValues.privcom = ''
      this.formConfirmationValues.organization = ''
      this.formConfirmationValues.eventcity = ''
      this.formConfirmationValues.guests = ''
      this.formConfirmationValues.date = ''
      this.formConfirmationValues.shortdesc = ''
      this.formConfirmationValues.description = ''
    }
  },
  beforeMount () {
    // this.getTomorrow()
  }
}
</script>

<style>
.main-content.request {
  margin-bottom: 0;
}

.request-container {
  background-color: var(--color-background-2);
  position: relative;
}

.contact-info {
  text-align: left;
}

.main-content.request {
    max-width: 1000px;
}

.request-form {
  margin-top: 2rem;
}

.request-form input,
.request-form select,
.request-form textarea {
  width: 100%;
  padding: 0.7rem 0.5rem;
  font-size: 1rem;
  border: 1px var(--color-light) solid;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  outline: none;
  background-color: var(--color-light);
  color: var(--color-highlight);
}

.request-form textarea {
  margin-bottom: 0;
}

.request-form input::placeholder,
.request-form select::placeholder,
.request-form textarea::placeholder {
  color: var(--color-input-placeholder);
}

.request-form input:focus,
.request-form select:focus,
.request-form textarea:focus {
  box-shadow: var(--input-shadow);
  border-color: var(--color-shadow);
}

input.haschanged:required:invalid, input.haschanged:focus:invalid,
select.haschanged:required:invalid, select.haschanged:focus:invalid,
textarea.haschanged:required:invalid, textarea.haschanged:focus:invalid {
  border-color: #ff0000;
  background-color: #fadddd;
  color: #ff0000;
}

input.haschanged:required:invalid::placeholder,
select.haschanged:required:invalid::placeholder,
textarea.haschanged:required:invalid::placeholder {
  color: #ff0000;
}

input.haschanged:focus:invalid,
select.haschanged:focus:invalid,
textarea.haschanged:focus:invalid {
  border-color: #ffffff;
  background-color: #fadddd;
}

input[type="submit"] {
  width: 50%;
  min-width: 200px;
  max-width: 400px;
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.multiline-confirmation {
  white-space: pre-wrap;
}

.total-characters {
  margin: 0;
  text-align: right;
  padding: 0 1rem;
}

.another-request {
  font-size: 1.5rem;
  text-align: center;
}

.recaptcha-style div:first-child {
  margin: auto;
}
</style>
