const COMPANY = {
  NAME: 'SWE Event & Artist',
  ADDRESS1: '',
  ADDRESS2: '',
  PHONE1: {
    DISPLAY: '073-026 17 44 ',
    CALL: '+46730261744'
  },
  PHONE2: {
    DISPLAY: '',
    CALL: ''
  },
  EMAIL: 'info@sweea.se',
  SOCIAL: {
    FB: 'https://www.facebook.com/SWEEA.SE',
    IG: 'https://www.instagram.com/sweevent_artist',
    TIKTOK: 'https://www.tiktok.com/@sweevent_artist'
  },
  DESCRIPTION: 'Hjälper dig att skapa ett event och skräddarsyr underhållningen!'
}

export default COMPANY
