<template>
  <footer id="contact" class="footer">
    <p>
      <span style="font-weight: 700;">{{$COMPANY.NAME}}</span>
      <!-- <br />{{$COMPANY.ADDRESS1}}
      <br />{{$COMPANY.ADDRESS2}} -->
      <br />
      <a v-bind:href="'tel:' + $COMPANY.PHONE1.CALL">{{$COMPANY.PHONE1.DISPLAY}}</a>
      <br />
      <a v-bind:href="'mailto:' + $COMPANY.EMAIL" target="_blank">{{$COMPANY.EMAIL}}</a>
    </p>
    <div class="social-links">
      <a v-bind:href="$COMPANY.SOCIAL.FB" target="_blank"><img src="../assets/fb-logo.png" alt="Facebook"/></a>
      <a v-bind:href="$COMPANY.SOCIAL.IG" target="_blank"><img src="../assets/ig-logo.png" alt="Instagram"/></a>
      <a v-bind:href="$COMPANY.SOCIAL.TIKTOK" target="_blank"><img src="../assets/tiktok-logo.png" alt="TikTok "/></a>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>
.footer {
  background-color: var(--color-dark);
  color: var(--color-light);
  margin: 0rem auto 0;
  padding: 2rem 1rem;
  position: relative;
  bottom: 0;
  font-size: .9rem;
}

.footer p {
  margin: 1rem;
}

a:link,
a:visited,
a:active {
  color: var(--color-light);
}

.footer a:hover {
  color: var(--color-light-highlight);
}
</style>
